

@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500&family=Roboto+Slab:wght@300&display=swap');

*{
  margin: 0;
  box-sizing: border-box;
}

html,body{
  overflow-x:hidden;
}
  
body{
  background-color: #f2f2f2;
  font-family: sans-serif;
  /* overflow: hidden; */
}

.header_main{
  font-family: sans-serif;
}

.dosrestros_logo_phone{
  display:none;
}

.logsin_small{
  display:none !important;
}


a{
  text-decoration: none;
  color: #fff;
}



/* flyer css */

.flyer_main{
  min-width: 900px;
}

.flyer_main:hover .spoon_Shadow{
  transform: scale(1.05);
}


input::placeholder{
  font-weight: bold;
  font-size: 20px;
}

.search_box input::placeholder{
  font-size: 15px;
}

.spoon_Shadow{
  width:90%;
  min-width: 900px;
  transition: 0.5s;
}

/* .spoon_Shadow:hover{
  transform: scale(1.1);
} */

.headline{
  color: #fff;
  position: absolute;
  font-size: 43px;
  font-weight: bold;
  margin-bottom: 10%;
}

/* a{
  text-decoration: none;
} */



/* hotelpagecss */
*{
  font-family:  sans-serif !important;
}
input.outlined-basic{
  border:5px solid red !important;
}

/* footer text css */
.ftxt1{
  margin-bottom: 1rem;
}


@media only screen and (max-width: 768px) {
  .dosrestros_logo_big{
    display:none;
  }
  .dosrestros_logo_phone{
    display:flex;
    margin-right: 25%;
  }
  .logsin_big{
    display:none !important;
  }
  .logsin_small{
    display:flex !important;
    padding:2px 5px 2px 5px;
  }
}
@media only screen and (max-width: 900px) {
  .headline{
    margin-bottom: 10%;
  }
}
@media only screen and (max-width: 415px) {
  .dosrestros_logo_big{
    display:none;
  }
  .logsin_big{
    display:none !important;
  }
  .logsin_small{
    display:flex !important;
    padding:2px 5px 2px 5px;
  }
  .dosrestros_logo_phone{
    display:flex;
    margin-right: 18%;
  }
}
